/* eslint-disable no-unused-vars */
export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REMOVED = 'Removed'
}
export enum PlanStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REMOVED = 'Removed',
  RESTRICTED_ACCESS = 'Restricted access'
}

export enum SubscriptionStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REMOVED = 'Removed',
  CANCELLED = 'Cancelled',
  PENDING_CANCELLED = 'Pending cancellation',
  COMPLETED = 'Completed'
}

export enum ChargeStatus {
  PENDING = 'Pending',
  FAILURE_SOFT = 'Soft failure',
  FAILURE_HARD = 'Hard failure',
  ERROR = 'Error',
  /**
   * @type {string}
   * @deprecated in favor of {@link ERROR}.
   */
  HARD_ERROR = 'Hard error',
  COMPLETED = 'Completed',
  SUCCESS = 'Success',
  SKIPPED = 'Skipped'
}

export enum Scopes {
  scope1 = 'scope1',
  scope2 = 'scope2',
  USR_REVOKE_JWT = 'usr_revoke_jwt',
  USR_GENERATE_JWT = 'usr_generate_jwt'
}

export enum Interval {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum Currency {
  USD = 'USD',
  ZAR = 'ZAR'
}

export enum Gateway {
  STRIPE = 'Stripe',
  PAYSTACK = 'Paystack',
  PAYFAST = 'Payfast',
  MOCK = 'Mock'
}

export enum PlanType {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  ENTERPRISE = 'Enterprise'
}

export interface StringTMap<T> {
  [key: string]: T;
}

export type StringStringMap = StringTMap<string>;
type ID = string;
type UserId = ID;
type OrgId = ID;
type Encrypted = string;

interface BaseType {
  id: ID;
  created: string;
  modified: string;
  status: Status;
}

export interface Org extends BaseType {
  name: string;
  type: 'live' | 'test';
  users: UserId[];
  access: {
    sourceIps: string[];
    scopes: string[];
    tokenIds: string[];
  };
  brand: Brand;
  gateways?: {
    mock?: {
      secureToken: Encrypted;
      [t: string]: string;
    };
    paystack?: {
      secureApiKey: Encrypted;
      [t: string]: string;
    };
    payfast?: {
      secureApiKey: Encrypted;
      merchantId: string;
      [t: string]: string;
    };
  };
  webhook: Webhook;
}

export interface Customer extends BaseType {
  id: string;
  fName: string;
  lName?: string;
  email: string;
  phone?: string;
  orgId: OrgId;
}

export interface User extends BaseType {
  id: string;
  fName: string;
  lName?: string;
  email: string;
}

export interface Plan extends BaseType {
  name: string;
  description?: string;
  amount: number; // 100.00
  interval: 'daily' | 'monthly' | 'yearly';
  currency: 'ZAR';
  iterations?: number; // 1, 6 etc.
  orgId: OrgId;
  metadata?: StringTMap<string | number>;
}

export interface Subscription extends BaseType {
  startDate: string;
  endDate?: string;
  cron: string;
  planId: ID;
  metadata: StringStringMap;
  customerId: ID;
  orgId: OrgId;
  status: any;
  iterations?: number;

  // Payment auth
  token: string; // Encrypted auth token for Payment gateway
  gateway: 'Paystack' | 'Payfast' | 'Mock';
  card: {
    bin?: string; // '408408'
    last4?: string; // '4081'
    expMonth: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    expYear: number; // '2030'
    cardType?: string;
    bank?: string; // 'TEST BANK'
    brand?: string;
    country: string;
  };
}

export interface Charge extends BaseType {
  status: Status;
  date: string;
  ref: string;
  subscriptionId: ID;
  amount: number;
  currency?: string;
  orgId: OrgId;
  customerId: ID;
  gwRef?: string; // ID/ref from gateway response
  error?: {
    name: string;
    message: string;
    description?: string;
  };
  attempt?: number;
  gwResp?: StringStringMap;
  customInfo?: StringStringMap;
}

export interface GetSettingsType {
  getSettings: Settings;
}

interface Settings extends OrganisationDetails {
  org: OrganisationDetails;
  gateways: Gateways;
  webhook: Webhook;
  notifications: Notifications;
  brand: Brand;
  access: Access;
}

export interface OrganisationDetails {
  name: string;
  address: Address;
  taxNumber: string;
  type: 'test' | 'live';
}

export interface Address {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
}

export interface Gateways {
  Mock: MockGatewayKeys;
  Paystack: StripeGatewayKeys;
  Payfast: PayfastGatewayKeys;
  Stripe: StripeGatewayKeys;
}

export interface MockGatewayKeys {
  secureToken: string;
}
export interface StripeGatewayKeys {
  secureApiKey: string;
  publishableKey: string;
}
export interface PayfastGatewayKeys {
  secureApiKey: string;
  merchantId: string;
  merchantKey: string;
}
export interface PaystackGatewayKeys {
  secureApiKey: string;
}

export interface Webhook {
  url: string;
}

export interface Brand {
  color: string;
  logoPath: string;
}

export interface Notifications {
  emails: [string];
  actions: [string];
}

export interface Access {
  sourceIps: string[];
  scopes: string[];
  tokenIds: string[];
}
