import dayjs from 'dayjs';
import cronParser from 'cron-parser';
import relativeTime from 'dayjs/plugin/relativeTime';
import { orderBy } from 'lodash';
import ordinal from 'ordinal';
import { ChargeStatus, Currency, Gateway, Interval, SubscriptionStatus } from '../types';

dayjs.extend(relativeTime);

export const phoneRegExp = /^((\+[1-9]{2})|0)[1-8][0-9]{8}$/;
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_.])[\w\d!@#$%^&*\-_.]/;
export const nameRegExp = /^[^±!@£$%^&*_+§¡€#¢¶•ªº«\\/<>?:;|=.,0-9()]*$/;
export const tempEmail = /^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+\.)+([a-zA-Z0-9]{2,})$/;
export const colorRegExp = /^(#?)(?:[0-9a-fA-F]{3}){1,2}$/;

export const getCustomerStatusColor = (status: string): string => {
  switch (status) {
    case 'Active':
      return 'green.400';
    case 'Error':
    case 'Cancelled':
      return 'red.400';
    case 'Pending cancellation':
      return 'yellow.400';
    default:
      return 'gray.400';
  }
};

export const getChargeStatusColor = (status: string): string => {
  switch (status) {
    case 'Active':
    case 'Completed':
    case 'Success':
      return 'green.400';
    case 'Error':
    case 'Soft failure':
    case 'Hard failure':
    case 'Cancelled':
      return 'red.400';
    case 'Pending cancellation':
    case 'Skipped':
    case 'Pending':
      return 'yellow.400';
    default:
      return 'gray.400';
  }
};

export const getSubscriptionStatusColor = (status: string): string => {
  switch (status) {
    case SubscriptionStatus.ACTIVE:
      return 'green.400';
    case 'Error':
      return 'red.400';
    case SubscriptionStatus.PENDING_CANCELLED:
    case 'Skipped':
      return 'yellow.400';
    // subscription Completed??
    case 'Completed':
    case SubscriptionStatus.CANCELLED:
      return 'gray.400';
    default:
      return 'gray.400';
  }
};

export const getPlanStatusColor = (status: string): string => {
  switch (status) {
    case 'Active':
      return 'green.400';
    case 'Error':
    case 'Cancelled':
      return 'red.400';
    case 'Pending cancellation':
    case 'Skipped':
      return 'yellow.400';
    default:
      return 'gray.400';
  }
};

export const currencyConverter = ({ currency, amount }: { currency: string; amount: string | number }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'ZAR'
  });
  // convert to number
  const monies = formatter.format(+amount);

  if (monies.startsWith('ZAR')) {
    const zar = monies.split('ZAR')[1];
    return `R${zar}`;
  }
  return monies;
};

export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  // const hello = new Date('2023-03-09T06:00:46.706Z');
  // console.log('2023-03-09T06:00:46.706Z');
  // return new Intl.DateTimeFormat('en-GB', {
  //   dateStyle: 'full',
  //   timeStyle: 'long'
  // }).format(hello);
  const date = new Date(dateString);
  // @ts-ignore
  return new Intl.DateTimeFormat('default', options).format(date);
};

export const getPercentageIncrease = (val1: number, val2: number): number => {
  return Math.round(((val1 - val2) / val2) * 100);
};

export const getSafeChargeDate = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const day = parseInt(date.format('D'), 10);

  // Normalise date for consistent charge day per month
  if (day > 28) {
    return dayjs(`${date.format('YYYY-MM')}-28`);
  }

  return date;
};

export const hasPausedSubscription = (startDate: string, createdDate?: string, safeChargeDate?: dayjs.Dayjs) => {
  const startDateObj = safeChargeDate || getSafeChargeDate(dayjs(startDate));

  // If start date is within 24 hours of the created date, its considered not a pause due to preventing duplicate charges
  if (createdDate && startDateObj.isBefore(dayjs(createdDate).add(1, 'day'))) {
    return false;
  }

  return startDate && dayjs().isBefore(startDateObj);
};

export const toCronExpression = (date: string, interval: 'monthly' | 'daily' = 'monthly'): string => {
  // eslint-disable-next-line default-case
  switch (interval) {
    case 'daily': {
      return `0 0 0 * * ?`;
    }
    case 'monthly': {
      const day = dayjs(date).get('date');

      // Bring all date after the 28th back to align with a predictable payment cycle for the shortest month
      if (day > 28) {
        return `0 0 0 28 * ?`;
      }

      return `0 0 0 ${day} * ?`;
    }
  }

  throw new Error('invalid date or interval');
};

export const getNextChargeDate = (cron: string, startDate?: string, created?: string): dayjs.Dayjs => {
  const startDateObj = getSafeChargeDate(dayjs(startDate));
  const isPaused = hasPausedSubscription(startDate, created, startDateObj);

  return getSafeChargeDate(
    dayjs(
      isPaused
        ? cronParser
            .parseExpression(cron, { currentDate: startDateObj.subtract(1, 'day').toDate() })
            .next()
            .toString()
        : cronParser.parseExpression(cron).next().toString()
    )
  );
};

export const cronToReadable = (cron: string, startDate?: string): string => {
  const date = getNextChargeDate(cron, startDate);

  if (date.fromNow(true) === 'a day') {
    return 'Tomorrow';
  }

  return `${date.format('D MMMM')} (in ${date.fromNow(true)})`;
};

export type TotalChargeAttempt = {
  key: number;
  doc_count: number;
  status: {
    buckets: {
      key: ChargeStatus;
      doc_count: number;
      revenue: {
        value: number;
      };
    }[];
  };
};

export type TotalCharges = {
  dates: {
    buckets: {
      [key: string]: {
        key_as_string: string;
        doc_count: number;
        attempt: {
          buckets: TotalChargeAttempt[];
        };
      };
    };
  };
};

export type TotalChargesSanitized = Record<
  'prev' | 'current',
  Record<
    'error' | 'success' | 'recovered',
    {
      count: number;
      value: number;
    }
  >
>;

export const sanitizeTotalCharges = (data: TotalCharges): TotalChargesSanitized => {
  const [prevData, currentData] = orderBy(Object.values(data.dates.buckets), ['key_as_string']);

  // Accommodate days when charges are not run e.g. > 28th
  const [prevAttempt1, prevAttempt2] =
    prevData?.attempt?.buckets?.[0]?.key === 1
      ? [prevData?.attempt?.buckets?.[0], prevData?.attempt?.buckets?.[1]]
      : [undefined, prevData?.attempt?.buckets?.[0]];

  const [currentAttempt1, currentAttempt2] =
    currentData?.attempt?.buckets?.[0]?.key === 1
      ? [currentData?.attempt?.buckets?.[0], currentData?.attempt?.buckets?.[1]]
      : [undefined, currentData?.attempt?.buckets?.[0]];

  const prev = {
    error: {
      count:
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.ERROR)?.doc_count || 0) +
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.PENDING)?.doc_count || 0),
      value:
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.ERROR)?.revenue.value || 0) +
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.PENDING)?.revenue.value || 0)
    },
    success: {
      count:
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.doc_count || 0) +
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.doc_count || 0),
      value:
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.revenue.value || 0) +
        (prevAttempt1.status.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.revenue.value || 0)
    },
    recovered: {
      count:
        (prevAttempt2?.status.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.doc_count || 0) +
        (prevAttempt2?.status.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.doc_count || 0),
      value:
        (prevAttempt2?.status.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.revenue.value || 0) +
        (prevAttempt2?.status.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.revenue.value || 0)
    }
  };
  const current = {
    error: {
      count:
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.ERROR)?.doc_count || 0) +
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.PENDING)?.doc_count || 0),
      value:
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.ERROR)?.revenue.value || 0) +
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.PENDING)?.revenue.value || 0)
    },
    success: {
      count:
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.doc_count || 0) +
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.doc_count || 0),
      value:
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.revenue.value || 0) +
        (currentAttempt1.status.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.revenue.value || 0)
    },
    recovered: {
      count:
        (currentAttempt2?.status?.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.doc_count || 0) +
        (currentAttempt2?.status?.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.doc_count || 0),
      value:
        (currentAttempt2?.status?.buckets.find((b) => b.key === ChargeStatus.SUCCESS)?.revenue.value || 0) +
        (currentAttempt2?.status?.buckets.find((b) => b.key === ChargeStatus.COMPLETED)?.revenue.value || 0)
    }
  };

  // Automatically offset recovered (attempt 2 = success) charges against attempt 1 errors for continuity
  return {
    prev: {
      success: {
        count: prev.success.count + prev.recovered.count, // Offset the recovered transaction count
        value: prev.success.value + prev.recovered.value // Offset the recovered transaction value
      },
      recovered: prev.recovered,
      error: {
        count: prev.error.count - prev.recovered.count, // Offset the recovered transaction count
        value: prev.error.value - prev.recovered.value // Offset the recovered transaction value
      }
    },
    current: {
      success: {
        count: current.success.count + current.recovered.count, // Offset the recovered transaction count
        value: current.success.value + current.recovered.value // Offset the recovered transaction value
      },
      recovered: current.recovered,
      error: {
        count: current.error.count - current.recovered.count, // Offset the recovered transaction count
        value: current.error.value - current.recovered.value // Offset the recovered transaction value
      }
    }
  };
};

export const sanitizeId = (id: string): string => {
  // Define a regular expression to match the prefixes
  const prefixPattern = /^(org_|cust_|pln_|sub_|chg_)/;

  // Replace the matched prefix with an empty string if found
  return id.replace(prefixPattern, '');
};
export const getCurrencies = (gateway: Gateway): Currency[] => {
  let currencies = [];

  switch (gateway) {
    case Gateway.MOCK:
    case Gateway.STRIPE:
      currencies = [Currency.USD, Currency.ZAR];
      break;
    case Gateway.PAYSTACK:
    case Gateway.PAYFAST:
      currencies = [Currency.ZAR];
      break;
    default:
      currencies = [];
  }

  return currencies;
};
export const getIntervals = (orgType: 'test' | 'live'): Interval[] => {
  if (orgType === 'test') {
    return Object.values(Interval);
  }

  return [Interval.MONTHLY, Interval.YEARLY];
};

export const isDashboardPage = (path: string): boolean => {
  const regex = /\/dashboard\/org_([A-Za-z0-9]+)\/(?:[^\/]+\/)?/;

  return !!path.match(regex);
};

export const isRedirectAttempt = (path: string): boolean => {
  const attemptRegex = /[?&]attempt=/;

  return attemptRegex.test(path);
};

export const getChargeInterval = (cron: string, status: string): string => {
  if (status !== 'Active') {
    return 'Cancelled';
  }
  if (cron === '0 0 0 * * *') {
    return 'Daily';
  }

  const day = cron.split(' ')[3];
  const currentDate = new Date();
  const curDay = currentDate.getDate() + 1;
  if (curDay < Number(day)) {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    if (month < 10) {
      if (Number(day) < 10) {
        return `${year}-0${month}-0${day}`;
      }
      return `${year}-0${month}-${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  if (curDay > Number(day)) {
    currentDate.setMonth(currentDate.getMonth() + 1);
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    if (month < 10) {
      if (Number(day) < 10) {
        return `${year}-0${month}-0${day}`;
      }
      return `${year}-0${month}-${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  if (day) {
    return ordinal(Number(day));
  }
  return '-';
};
